import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
interface Props {
  data: any
  pageContext: any
}

const AboutUs = ({ data, pageContext }: Props) => {
  const { wpPage } = data
  // const lazyBlocks = parse(wpPage.lazy_data)
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)
  // console.log(renderedBlocks)
  return <Layout>{renderedBlocks.map((v) => v)}</Layout>
}
export default AboutUs

export const pageQuery = graphql`
  query aboutUs {
    wpPage(title: { eq: "About Us" }) {
      id
      lazy_data
      title
    }
  }
`
